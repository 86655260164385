import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { Check, FocusAreaIcon } from "../components/svg";
import Seo from "../components/Seo";

const HomePage = () => (
  <Layout isHomepage>
    <Seo title="Home" />

    <div>
      {/* value proposition */}

      <div className="container max-w-screen-3xl -mt-20 sm:-mt-32">
        <div className="bg-white px-4 sm:px-12 lg:px-24 py-6 sm:py-12 lg:py-24">
          <h2 className="text-center text-blue-800 text-3xl leading-tight sm:text-5xl sm:leading-snug">We help Governments and companies to execute Digital Transformation</h2>

          <ul className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8 sm:text-2xl mt-8 sm:mt-12 lg:mt-24">
            {valueData.map(function (data) {
              return (
                <li key={data.id} className="flex items-start">
                  <div>
                    <Check className="w-auto h-6 sm:h-10 mt-px" />
                  </div>
                  <p className="ml-3 sm:ml-6" dangerouslySetInnerHTML={{ __html: data.title }}></p>
                </li>
              );
            })}
          </ul>

          <div className="text-center mt-8 sm:mt-16">
            <a
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=-BViEsdqisU"
              target="_blank"
              className="inline-block border-2 border-yellow-800 bg-yellow-800 text-gray-900 font-bold w-full sm:w-96 p-5 sm:py-5 mt-2 sm:mt-0 sm:text-xl sm:px-12 sm:mx-2"
            >
              Watch video introduction
            </a>
            <Link to="/about/" className="inline-block border-2 border-yellow-800 bg-white text-gray-900 font-bold w-full sm:w-96 p-5 sm:py-5 mt-2 sm:mt-4 xl:mt-0 sm:text-xl sm:px-12 sm:mx-2">
              Learn more about us
            </Link>
          </div>
        </div>
      </div>

      {/* business areas */}

      <div className="container py-16 sm:py-32">
        <h2 className="text-center text-blue-800 text-3xl leading-tight sm:text-5xl sm:leading-snug">Business areas we are focusing on</h2>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 sm:gap-24 lg:gap-8 mt-20 sm:mt-36">
          {areasData.map(function (data) {
            return (
              <div key={data.id} className="relative bg-white p-6 sm:p-12 sm:pb-24">
                <div className="-mt-14 sm:-mt-24 mb-6 sm:mb-12">
                  <FocusAreaIcon icon={data.icon} className="w-auto h-32 sm:h-48 mx-auto text-blue-800 fill-current" />
                </div>
                <h3 className="text-2xl sm:text-4xl text-center text-blue-800">
                  <Link to={data.url}>{data.title}</Link>
                </h3>
                <p className="mt-4 sm:mt-8">{data.content}</p>
                <p className="sm:absolute sm:inset-x-0 sm:bottom-12 text-center">
                  <Link className="inline-block text-blue-800 my-4 sm:m-0" to={data.url}>
                    Read more
                  </Link>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </Layout>
);

const valueData = [
  {
    id: 0,
    title: "Providing <strong>digital identification and signing</strong>",
  },
  {
    id: 1,
    title: "Designing proof based and reliable <strong>data governance systems</strong> that operate at low risk",
  },
  {
    id: 2,
    title: "Enabling <strong>interoperability</strong> between IT systems and registries",
  },
  {
    id: 3,
    title: " Turning trust-based solutions into <strong>proof-based platform</strong> by implementing Proof Technologies incl. Blockchain or other DLTs",
  },
  {
    id: 4,
    title: "Securing the <strong>compliance</strong> of data systems with the surrounding regulatory environment (eg. GDPR, AML)",
  },
  {
    id: 5,
    title: "Assessing the <strong>attack surface</strong> of the current IT system and infrastructure",
  },
];

const areasData = [
  {
    id: 0,
    title: "Government",
    content:
      "Nearly all countries and Governments aspire to use digital technologies in providing services to its citizens and residents. Levecode focuses on basic foundational data systems that are of vital importance for effective digital governance and integrity of the whole ecosystem.",
    icon: "government",
    url: "/expertise/government/",
  },
  {
    id: 1,
    title: "Healthcare",
    content:
      "Information about our health is one of the most important things to us. The same goes for getting proper hospital treatment or healthcare advice. Accurate information regarding person’s blood type, chronic health issues, and medication history literally become a matter of life and death.",
    icon: "healthcare",
    url: "/expertise/healthcare/",
  },
  {
    id: 2,
    title: "Fintech",
    content:
      "Financial Management and Services are the areas that have reaped tremendous benefits from technological advancements. Besides making it faster and more convenient to transfer funds from one entity to another, digital currencies have opened a whole new array of opportunities for transferring of value, ownership, and accumulating wealth.",
    icon: "fintech",
    url: "/expertise/fintech/",
  },
];

export default HomePage;
